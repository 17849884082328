import React from 'react';
import Slide1 from '../../assets/images/slide-1.png'
import Slide2 from '../../assets/images/slide-2.webp'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

function Home() {
    return (
        <div>

            <section class="section gradient-banner">
                <Swiper
                    className='home-slider'
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    <SwiperSlide>
                        <div class="item">
                            <img src={Slide2} />
                            <div class="container">
                                <div class="text fade-in">
                                    <span>
                                        Yenilikçi, kaliteli <br />
                                        ve güvenilir çözümler
                                    </span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="item">
                            <img src={Slide1} />
                            <div class="container">
                                <div class="text fade-in">
                                    <span>
                                        Sağlığınız <br />
                                        bizim önceliğimiz
                                    </span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>

            <section class="section pt-0 position-relative why-pharmatrue">
                <div class="container">
                    <div class="rounded ">
                        <div class="row">
                            <div class="title col-12">
                                <span>NEDEN PHARMATRUE?</span>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                <div class="item animate__animated animate__fadeIn">
                                    <div class="icon">UZMANLIK</div>
                                    <div class="text">Geniş birikim ve uzmanlığa sahibiz.</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                <div class="item animate__animated animate__fadeIn">
                                    <div class="icon">YENİLİKÇİ</div>
                                    <div class="text">Sektördeki yenilikleri takip ederek, yenilikçi ve yaratıcı bir vizyonla üretim teknolojimizi sürekli güncelleyerek sizlere sunuyoruz.</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                                <div class="item animate__animated animate__fadeIn">
                                    <div class="icon">KALİTE VE GÜVEN</div>
                                    <div class="text">Kaliteli ürünlerimiz ve güvenli çözümlerimizle yanınızdayız.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;