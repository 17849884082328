import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import '../assets/plugins/bootstrap/bootstrap.min.css'
import '../index.css';
import '../assets/plugins/themify-icons/themify-icons.css';

import Home from '../pages/Home';
import Products from '../pages/products'
import About from '../pages/About'
import Contact from '../pages/Contact'

const AppRouter = () => {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
    </Router>
  );
}

export default AppRouter;
