import React from 'react'
import PropTypes from 'prop-types'

function index(props) {
    return (
        <div>
            <section class="section page-title">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-8 m-auto">
                            <h1>Hakkımızda</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="privacy section pt-0">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 sm-hidden">
                            <nav class="privacy-nav">
                                <ul>
                                    <li><a class="nav-link scrollTo" href="#mission" class="scrollTo">Misyonumuz</a></li>
                                    <li><a class="nav-link scrollTo" href="#vision" class="scrollTo">Vizyonumuz</a></li>
                                    <li><a class="nav-link scrollTo" href="#quality" class="scrollTo">Kalite ve Sürdürülebilirlik</a></li>
                                    <li><a class="nav-link scrollTo" href="#principle" class="scrollTo">Temel Prensiplerimiz</a></li>
                                    <li><a class="nav-link scrollTo" href="#feature" class="scrollTo">Geleceğe Yönelik Vizyonumuz</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-lg-9">
                            <div class="block">
                                <div id="userLicense" class="policy-item">

                                    <div class="policy-details">
                                        <p>
                                        Pharmatrue Sağlık Malzemeleri A.Ş, 2023 yılında, sağlık sektörüne ilaç alanında
                                        yenilikçi yatırımlarla taze bir soluk getirmek ve katkı sağlamak amacıyla kurulmuştur.
                                        Bu iddialı adımı, &quot;Geleceğe Doğru Bir Adım&quot; mottosuyla birleştiren Pharmatrue,
                                        kalite, araştırma, yenilikçilik ve son teknolojiye olan bağlılığıyla öne çıkmaktadır.
                                        Temel değerlerimiz arasında bulunan bu ilkeler, ilaç sektöründe sıra dışı bir etki
                                        yaratmayı hedeflemektedir.
                                        </p><p>
                                        Geniş bir terapötik alanı kapsayan ürün yelpazemiz, göğüs hastalıkları, dahiliye,
                                        gastroenteroloji, ortopedi, fizik tedavi ve rehabilitasyon, kulak-burun-boğaz, pediatri,
                                        aile hekimliği ve acil hekimliği gibi çeşitli alanlarda hastaların yaşam kalitesini
                                        artırmayı amaçlamaktadır. Pharmatrue olarak, her hastanın bireysel ihtiyaçlarına ve
                                        sağlık gereksinimlerine odaklanarak, kapsamlı ve yenilikçi çözümler sunmaya
                                        kararlıyız.
                                        </p><p>
                                        Firmamızın kuruluş amacı, sadece hastalarımıza ilaç sağlamak değil, aynı zamanda
                                        sağlık sektöründe de dönüştürücü bir rol oynamaktır. Bu doğrultuda, sürekli olarak
                                        araştırma ve geliştirme faaliyetleri yürütüyor, sektördeki en son bilimsel ve teknolojik
                                        gelişmeleri yakından takip ediyoruz. Bu sayede, hastalarımızın güven ve
                                        memnuniyetini en üst düzeye çıkarmayı ve onlara en etkili tedavi seçeneklerini
                                        sunmayı hedefliyoruz.
                                        </p><p>
                                        Pharmatrue Sağlık Malzemeleri A.Ş olarak, sağlık sektöründe ilaç alanında fark
                                        yaratan bir kuruluş olma hedefimiz doğrultusunda, kalite, araştırma, yenilikçilik ve
                                        toplumsal sorumluluk ilkelerine sıkı sıkıya bağlı kalarak, müşterilerimize ve topluma
                                        değer katmaya devam edeceğiz. Geleceğe umutla bakıyor, daha sağlıklı bir dünya
                                        için çalışıyoruz.
                                        </p>
                                    </div>
                                </div>
                                <div id="mission" class="policy-item">
                                    <div class="title">
                                        <h3>Misyonumuz</h3>
                                    </div>
                                    <div class="policy-details">
                                        <p>Sağlık alanında en kaliteli ve güvenilir ürünleri sunarak, sağlık hizmetlerinin iyileştirilmesine katkıda bulunmaktır.</p>
                                    </div>
                                </div>
                                <div id="vision" class="policy-item">
                                    <div class="title">
                                        <h3>Vizyonumuz</h3>
                                    </div>
                                    <div class="policy-details">
                                        <p>Sektörde sürekli olarak ileri teknoloji ve yenilikçi çözümlerle öncü olmaya devam etmektir.</p>
                                    </div>
                                </div>
                                <div id="quality" class="policy-item">
                                    <div class="title">
                                        <h3>Kalite ve Sürdürülebilirlik</h3>
                                    </div>
                                    <div class="policy-details">
                                        <p>
                                            En yüksek hijyen standartlarına ve uluslararası kalite belgesine sahibiz. Çevreye duyarlılık, sürdürülebilirlik ve insana değer verme ilkeleriyle faaliyetlerimizi yürütüyoruz.
                                        </p>
                                    </div>
                                </div>
                                <div id="principle" class="policy-item">
                                    <div class="title">
                                        <h3>Pharmatrue'nun Temel Prensipleri:</h3>
                                    </div>
                                    <div class="policy-details">
                                        <p>
                                            Kalite ve Güvenilirlik: Etik değerlere bağlı kalarak, güvenilir ve sürdürülebilir ilaç üretimi ile sağlık profesyonellerinin gözünde itimat edilir bir marka olmayı hedefliyoruz.
                                        </p>
                                        <p>
                                            Bilimsel Mükemmellik: Bilimsel araştırmalar ve geliştirme çalışmaları üzerine odaklanarak, hastalıkların nedenlerini anlama, en etkili tedavileri geliştirme ve dünyadaki tüm yenilikleri takip
                                            etme misyonuyla yola çıkıyoruz.
                                        </p>
                                        <p>
                                            Ekip Ruhu: Pharmatrue ailesi olarak, her bir çalışan arkadaşımızın büyük bir değer olduğu bilinciyle yeteneklerini en üst seviyede kullanabileceği bir çalışma ortamı yarattık, işbirliği ve
                                            iletişimi destekleyerek güçlü bir ekip oluşturduk.
                                        </p>
                                    </div>
                                </div>
                                <div id="feature" class="policy-item">
                                    <div class="title">
                                        <h3>Geleceğe Yönelik Vizyonumuz:</h3>
                                    </div>
                                    <div class="policy-details">
                                        <p>
                                            Pharmatrue olarak, tüm terapötik alanlara yönelik yatırımlarımızla, insan sağlığını korumaya ve geliştirmeye yönelik katkılarımızı artıracağız. Bilimsel ve teknolojik dünyadaki tüm gelişmeleri
                                            yakından takip ederek, ilaç sektöründeki yenilikleri liderlik eden bir konumda takip edeceğiz.
                                        </p>
                                        <p>
                                            Amacımız, hastalıklarla mücadelede toplumun yaşam kalitesini yükseltmektir. Tüm paydaşlarımızla sürdürülebilir işbirliği içerisinde, sağlığın her alanında, insana ve çevreye duyarlılıkla,
                                            daha sağlıklı bir dünya için var gücümüzle çalışıyoruz.
                                        </p>
                                        <p>
                                            Pharmatrue olarak, her adımımızda sağlığın önemini ve değerini taşıyoruz. Geleceğe yönelik planlarımızla, daha fazla hastalığa ulaşmak, tedavi edici ve koruyucu çözümler sunmak için
                                            çalışmalarımızı kararlılıkla sürdüreceğiz.
                                        </p>
                                        <p>
                                            Sağlığa olan inancımızla Pharmatrue, geleceğin sağlık çözümlerini şekillendirecek bir liderlik rolü üstlenmeye hazırdır.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
        </div >
    )
}

export default index
