import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'black',
      color: 'white',
      textAlign: 'center',
      padding: '10px',
      zIndex: 1000
    }}>
      Bu site çerezler kullanmaktadır. Daha fazla bilgi için <a href="/privacy-policy" style={{color: 'white'}}>Gizlilik Politikamızı</a> ziyaret edin.
      <button onClick={handleAccept} style={{marginLeft: '15px', padding: '5px 10px', cursor: 'pointer'}}>
        Kabul Et
      </button>
    </div>
  );
};

export default CookieBanner;
