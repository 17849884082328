import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo.png'


function Header(props) {
    const [show, setShow] = useState(false);

    const toggleNavbar = () => {
        setShow(!show);
    };

    return (
        <div>
            <nav className="navbar main-nav navbar-expand-lg px-2 px-sm-0 py-2 py-lg-0">
                <div className="container">
                    <a className="navbar-brand" href="/"><img src={Logo} alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" onClick={toggleNavbar}
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="ti-menu"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${show ? 'show' : ''}`} id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/" >
                                    ANASAYFA
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">KURUMSAL
                                    <span><i className="ti-angle-down"></i></span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/about">Hakkımızda</a></li>
                                    <li><a className="dropdown-item" href="#">Belgelerimiz</a></li>
                                </ul>
                            </li>
                            <li className="nav-item @@contact">
                                <a className="nav-link" href="/products">ÜRÜNLER</a>
                            </li>
                            <li className="nav-item @@contact">
                                <a className="nav-link" href="/contact">İLETİŞİM</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}


export default Header
