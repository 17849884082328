import React from 'react'
import Logo from '../assets/images/logo.png'


function Footer(props) {
    return (
        <div>
            <footer>
                <div className="footer-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-12 m-md-auto align-self-center">
                                <div className="block">
                                    <a href="index.html"><img src={Logo} height="15" alt="footer-logo" /></a>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6 mt-5 mt-lg-0">
                                <div className="block-2">
                                    <ul className="list">
                                        <li><a href="/">ANASAYFA</a></li>
                                        <li><a href="/about">HAKKIMIZDA</a></li>
                                        <li><a href="/products">ÜRÜNLER</a></li>
                                        <li><a href="/contact">İLETİŞİM</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-3 col-6 mt-5 mt-lg-0">
                                <div className="block-2">
                                    <ul>
                                        <li>
                                            Yassıören Mah.<br />
                                            Ağaçkakan Sok. No:15/A <br />
                                            Arnavutköy / İstanbul / Türkiye
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6 mt-5 mt-lg-0">
                                <div className="block-2">
                                    <ul>
                                        <li>
                                            <a href="tel:4448717">444 8 717</a> <br />
                                            <a href="mailto:info@pharmatrue.com.tr">info@pharmatrue.com.tr</a>
                                        </li>
                                    </ul>
                                    <div className="block">
                                        <ul className="social-icon list-inline">
                                            <li className="list-inline-item">
                                                <a href="#"><i className="ti-facebook"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#"><i className="ti-twitter"></i></a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="#"><i className="ti-instagram"></i></a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="scroll-top-to">
                <i className="ti-angle-up"></i>
            </div>
        </div>
    )
}


export default Footer
