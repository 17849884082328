import { useEffect, useState } from 'react';

import PropTypes from 'prop-types'
import DownloadIcon from '../../assets/images/download.svg'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const products = [
    {
        name: "TALAVİST 5mg",
        size: "5mg",
        form: "Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Tadalafil",
        image: "talavist/talavist-5mg.jpg",
        kub: "/images/products/talavist/Talavist 5mg-KUB.pdf",
        kt: "/images/products/talavist/Talavist 5mg-KT.pdf",
    },
    {
        name: "TALAVİST 20mg",
        size: "20mg",
        form: "Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Tadalafil",
        image: "talavist/talavist-20mg.jpg",
        kub: "/images/products/talavist/Talavist 20mg-KUB.pdf",
        kt: "/images/products/talavist/Talavist 20mg-KT.pdf",
    },
    {
        name: "RAVENTRIS 10mg",
        size: "10mg",
        form: "Dağılabilir Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Rasekadotril",
        image: "raventris/raventris-10mg.jpg",
        kub: "/images/products/raventris/Raventris 10mg-KUB.pdf",
        kt: "/images/products/raventris/Raventris 10mg-KT.pdf",
    },
    {
        name: "RAVENTRIS 30mg",
        size: "30mg",
        form: "Dağılabilir Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Rasekadotril",
        image: "raventris/raventris-30mg.jpg",
        kub: "/images/products/raventris/Raventris 30mg-KUB.pdf",
        kt: "/images/products/raventris/Raventris 30mg-KT.pdf",
    },
    {
        name: "RAVENTRIS 100mg",
        size: "100mg",
        form: "Sert Kapsül",
        type: "Film Kaplı Tablet",
        activeMade: "Rasekadotril",
        image: "raventris/raventris-100mg.jpg",
        kub: "/images/products/raventris/Raventris 100mg-KUB.pdf",
        kt: "/images/products/raventris/Raventris 100mg-KT.pdf",
    },
    {
        name: "DEKSOLEX 25mg",
        size: "25mg",
        form: "Dağılabilir Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Deksketoprofene",
        image: "deksolex/deksolex-25mg.jpg",
        kub: "/images/products/deksolex/Deksolex-25-KUB.pdf",
        kt: "/images/products/deksolex/Deksolex-25-KT.pdf",
    },
    {
        name: "KİNOMOX 400mg",
        size: "400mg",
        form: "Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Moksifloksasin",
        image: "kinomox/kinomox-400mg.jpg",
        kub: "/images/products/kinomox/Kinomox-400mg-KUB.pdf",
        kt: "/images/products/kinomox/Kinomox-400mg-KT.pdf",
    },
    {
        name: "TİO DEKSOLEX 25mg/4mg",
        size: "25mg/4mg",
        form: "Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Deksketoprofen Trometamol, Tiyokolşikosid",
        image: "tio-deksolex/tio-deksolex-25mg-4mg.jpg",
        kub: "/images/products/tio-deksolex/Tio-Deksolex 25-4-KUB.pdf",
        kt: "/images/products/tio-deksolex/Tio-Deksolex 25-4-KT.pdf",
    },
    {
        name: "TİO DEKSOLEX 25mg/8mg",
        size: "25mg/8mg",
        form: "Tablet",
        type: "Film Kaplı Tablet",
        activeMade: "Deksketoprofen Trometamol, Tiyokolşikosid",
        image: "tio-deksolex/tio-deksolex-25mg-8mg.jpg",
        kub: "/images/products/tio-deksolex/Tio-Deksolex 25-8-KUB.pdf",
        kt: "/images/products/tio-deksolex/Tio-Deksolex 25-8-KT.pdf",
    },
    {
        name: "Protexus Nazal Sprey Yetişkin",
        size: "20ml",
        form: "Spray",
        type: "Spray",
        activeMade: "-",
        image: "protexus-hypertonic-nasal/protexus-hypertonic-nasal.png",
        kub: "/images/products/protexus-hypertonic-nasal/protexus-hypertonic-nasal.pdf",
        kt: "/images/products/protexus-hypertonic-nasal/protexus-hypertonic-nasal.pdf",
    },
    {
        name: "Protexus Nazal Sprey Pediatrik",
        size: "20ml",
        form: "Spray",
        type: "Spray",
        activeMade: "-",
        image: "protexus-hypertonic-nasal-bambini/protexus-hypertonic-nasal-bambini.png",
        kub: "/images/products/protexus-hypertonic-nasal-bambini/protexus-hypertonic-nasal-bambini.pdf",
        kt: "/images/products/protexus-hypertonic-nasal-bambini/protexus-hypertonic-nasal-bambini.pdf",
    },
    {
        name: "Protexus Boğaz Spreyi",
        size: "20ml",
        form: "Spray",
        type: "Spray",
        activeMade: "-",
        image: "protexus-throat/protexus-throat.png",
        kub: "/images/products/protexus-throat/protexus-throat.pdf",
        kt: "/images/products/protexus-throat/protexus-throat.pdf",
    },
    {
        name: "Protexus Öksürük Şurubu",
        size: "170ml",
        form: "Syrup",
        type: "Syrup",
        activeMade: "-",
        image: "protexus-cought-cold/protexus-cought-cold.png",
        kub: "/images/products/protexus-cought-cold/protexus-cought-cold.pdf",
        kt: "/images/products/protexus-cought-cold/protexus-cought-cold.pdf",
    },
    {
        name: "Acidblock Çiğneme Tableti",
        size: "130x160",
        form: "Tablet",
        type: "Tablet",
        activeMade: "-",
        image: "acidblock-cpr/acidblock-cpr.png",
        kub: "/images/products/acidblock-cpr/acidblock-cpr.pdf",
        kt: "/images/products/acidblock-cpr/acidblock-cpr.pdf",
    },
]

const ProductList = () => {
    const [open, setOpen] = useState(true);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const redirectButton = (type) => {
        if(!type) {
            window.location.href = "/";
        } else {
            handleClose();
        }
    }

    return (
        <div>
            <div className="container mt-5 mb-5">
                <div className="d-flex justify-content-center row">
                    <div className="col-md-12">
                        {
                            products.map((item, index) => (
                                <div className="row p-2 bg-white border rounded product-list-item" key={index}>
                                    <div className="col-md-8">
                                        <h5 className='mt-2'>{item.name}</h5>
                                        <div className="d-flex flex-row mb-3">
                                            <b>{item.activeMade}</b>
                                        </div>
                                        <div className="mt-1 mb-1 spec-1 product-detail-list">
                                            <div className='item'><b>Form</b><span>{item.form}</span></div>
                                            <div className='item'><b>Etkin Madde</b><span>{item.activeMade}</span></div>
                                            <div className='item'><b>Ticari Şekil</b><span>{item.size} / {item.form}</span></div>
                                            <div className='item'><b>KÜB</b><a href={item.kub} target='_blank' rel='noopener noreferrer'>PDF <img src={DownloadIcon} alt="Download Icon" /></a></div>
                                            <div className='item'><b>KT</b><a href={item.kt} target='_blank' rel='noopener noreferrer'>PDF <img src={DownloadIcon} alt="Download Icon" /></a></div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <img className="img-fluid img-responsive rounded product-image" src={`/images/products/${item.image}`} alt={item.name} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h2" component="h3" className='mb-3'>
                        Sağlık personeli misiniz?
                    </Typography>
                    <Button variant="contained" className='mr-3' onClick={() => redirectButton(true)}>EVET</Button>
                    <Button variant="contained" onClick={() => redirectButton(false)}>HAYIR</Button>
                </Box>
            </Modal>
        </div>
    );
}

ProductList.propTypes = {
    // Eğer propTypes tanımlamak isterseniz buraya ekleyebilirsiniz.
};

export default ProductList;