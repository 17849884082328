import React from 'react'
import GoogleMapReact from 'google-map-react';


function Contact() {
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const defaultProps = {
        center: {
            lat: 41.23787770528859,
            lng: 28.592435091146182
        },
        zoom: 15
    };
    return (
        <div>
            <section className="section page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 m-auto">
                            <h1>Bize ulaşın</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="address">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 align-self-center">
                            <div className="block">
                                <div className="address-block text-center mb-5">
                                    <div className="icon">
                                        <i className="ti-mobile"></i>
                                    </div>
                                    <div className="details">
                                        <h3>444 8 717</h3>
                                        <h3>info@pharmatrue.com.tr</h3>
                                    </div>
                                </div>
                                <div className="address-block text-center">
                                    <div className="icon">
                                        <i className="ti-map-alt"></i>
                                    </div>
                                    <div className="details">
                                        <h3>Merkez Yassıören Mah. Ağaçkakan Sok. No:15/A
                                            Arnavutköy / İstanbul / Türkiye</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 mt-5 mt-lg-0">
                            <div className="google-map" style={{ height: '100vh', width: '100%' }}>
                            
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyCK5Emc18o2VaHeJzls5hRUWzSZqECrwMI" }}
                                    defaultCenter={defaultProps.center}
                                    defaultZoom={defaultProps.zoom}
                                >
                                    <AnyReactComponent
                                        lat={41.23787770528859}
                                        lng={28.592435091146182}
                                        text="Pharmatrue"
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact