// src/App.js
import React from 'react';
import AppRouter from './router/AppRouter';
import Header from './layout/Header';
import Footer from './layout/Footer';
import CookieBanner from './components/CookieBanner';

function App() {
  return (
    <div>
      <Header />
      <AppRouter />
      <Footer />
      <CookieBanner />
    </div>
  );
}

export default App;
